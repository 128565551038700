
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
// import EditCampana from "./edit-campana.vue";
import PeopleFind from "../../../components/People/people-find.vue";
import Campana from "../../../store/entities/OZONE/campana";
import Exportacion from "../../../store/entities/OZONE/exportacion";
import Transferencia from "../../../store/entities/OZONE/transferencia";
import Importacion from "../../../store/entities/OZONE/importacion";
import Envio from "../../../store/entities/OZONE/envio";
import WidgetCounter from "../../../components/Muse/Widgets/WidgetCounter.vue";
import moment, { Moment } from "moment";
import XLSX, { read } from "xlsx";
import { InputGroupPlugin } from "bootstrap-vue";
import SelectCampana from "../campana/selectCampana.vue";
import { ProtocolMode } from "@azure/msal-common";
import EnvioDato from "../../../store/entities/OZONE/envioDato";

class PageCamposRequest extends PageRequest {
  campanaId: number;
}

@Component({
  components: {
    //EditCampana,
    PeopleFind,
    WidgetCounter,
    SelectCampana,
  },
})
export default class ImportEnvios extends AbpBase {
  fileList = [];
  actions = [];

  fieldMapping: {
    fName: string;
    fKey: string;
    mId: number;
    mName: {
      label: string;
      key: string;
    };
    mOrder: number;
    opts: number[];
  }[] = [];

  groupedFields = [];

  referenceMapping = [];
  fields = [];
  mapping = [];
  records = [];
  campos = [];
  camposSelect = [];
  campana = null;
  percent = 0;
  total = 0;

  envios: Array<Envio> = [];
  wrongIBANs: Array<string> = [];
  current = 0;
  wrongFormat = false;
  done = false;

  labelCol = { span: 4 };
  wrapperCol = { span: 14 };

  form = {
    name: "",
    mailing: "",
    desc: "",
    envtype: 1,
    fecenv: "",
  };

  rules = {
    name: [
      {
        required: true,
        message: "El origen es obligatorio",
        trigger: "blur",
      },
    ],
    envtype: [
      {
        required: true,
        message: "El tipo es obligatorio",
        trigger: "blur",
      },
    ],
    desc: [
      { min: 0, max: 5000, message: "Máximo 5000 caracteres", trigger: "blur" },
    ],
  };

  steps: { title; content }[] = [
    {
      title: "Cargar Fichero",
      content: "First-content",
    },
    {
      title: "Mapeo Campaña",
      content: "First-content",
    },
    {
      title: "Resumen & Validación",
      content: "Second-content",
    },
    {
      title: "Carga",
      content: "Last-content",
    },
    {
      title: "Finalizado",
      content: "Last-content",
    },
  ];

  metodosEnvio: {}[] = [
    {
      id: 1,
      text: "Pendiente",
    },
    {
      id: 2,
      text: "Enviado",
    },
  ];

  handleChange(value) {}

  next() {
    this.current = this.current + 1;
  }

  prev() {
    if (this.current == 1) {
      this.envios = [];
      this.fileList = [];
    }

    this.current = this.current - 1;
  }

  reset() {
    location.reload();
  }

  get loading() {
    return this.$store.state.transferencia.loading;
  }

  fetching: boolean = false;

  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }

  beforeUpload(file) {
    this.envios = [];
    this.fileList = [file];
    this.readFile();

    return false;
  }

  readFile() {
    if (this.fileList && this.fileList.length > 0) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, 
        { 
          type: "string",
          raw: true, 
          cellDates: false, 
          cellText: true 
        });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        
        const dataExcel = XLSX.utils.sheet_to_json(ws, 
        {
          defval: '',
          blankrows: true
        });

        this.total = dataExcel.length;
        dataExcel.forEach((value, index) => {
          if (index == 0) {
            for (var key in dataExcel[index] as any) {
              this.fieldMapping.push({
                fKey: key,
                fName: key,
                mId: 0,
                mName: {
                  key: "",
                  label: "",
                },
                mOrder: 0,
                opts: [],
              });
            }
          }
          
          this.records.push(value);
          this.percent = ((index + 1) * 100) / this.total;
        });
      };

      reader.readAsText(this.fileList[0], 'UTF-8');
    }
  }

  setMapping(v) {
    var result = this.fieldMapping.reduce(function (r, a) {
      r[a.mName.key] = r[a.mName.key] || [];
      r[a.mName.key].push(a);
      return r;
    }, Object.create(null));

    this.groupedFields = result;
    this.current = this.current + 1;
  }

  @Watch("campana")
  async getCampanaCampos() {
    this.fetching = true;
    var camposRequest = new PageCamposRequest();
    camposRequest.campanaId = this.campana.id;
    camposRequest.maxResultCount = 50;
    camposRequest.skipCount = 0;
    await this.$store.dispatch({
      type: "campana/getAllCampos",
      data: camposRequest,
    });

    this.campos = this.$store.state.campana.camposList;
    let camposSelect = this.$store.state.campana.camposList.map((campo) => ({
      text: campo.nombre.toUpperCase(),
      value: campo.nombre,
      custom: true,
      selected: false,
      id: campo.id,
    }));

    var e = new Envio();
    e.direccion = "";
    e.localidad = "";
    e.nombre = "";
    e.apellido1 = "";
    e.apellido2 = "";
    //e.referencia = "";
    e.dNI = "";
    e.email = "";
    e.cP = "";
    e.ekon = "";
    e.incentivo = "";
    e.articulo = "";
    e.descripcionArticulo = "";
    e.articuloUnidades = 0;
    e.pais = "";
    e.localidad = "";
    e.provincia = "";
    e.telefono = "";

    for (var ce in e) {
      camposSelect.push({
        text: ce.toUpperCase(),
        value: ce,
        custom: false,
        selected: false,
        id: 0,
      });
    }

    camposSelect.push({
      text: "SIN MAPEAR",
      value: "nomap",
      custom: false,
      selected: false,
      id: 0,
    });

    this.camposSelect = camposSelect;
    this.fetching = false;
  }

  checkMap(value) {
    var arr = this.fieldMapping;
    arr.forEach(function (part, index) {
      var count = arr.reduce(
        (acc, cur) =>
          cur.mName.key == part.mName.key &&
          part.mName.key &&
          part.mName.key != "nomap"
            ? ++acc
            : acc,
        0
      );

      arr[index].opts = [];
      if (count > 1) {
        for (var i = 1; i < count + 1; i++) {
          arr[index].opts.push(i);
        }
      }
    });

    this.fieldMapping = arr;
  }

  getCount(field) {
    let prcnt = 0;
    let count = 0;
    field.forEach((element) => {
      let key = field[0].fKey;
      let kCount = this.records.filter(
        (obj) => obj[key] && obj[key] != ""
      ).length;
      prcnt += (kCount * 100) / this.records.length;
      count++;
    });

    return (prcnt / count).toFixed(0);
  }

  getExample(field) {
    let ex = "";
    let row = this.records[0];
    field.forEach((element) => {
      ex += row[element.fKey];
    });

    return ex;
  }

  getTestRef() {
    var referencia = "";

    if (this.referenceMapping && this.referenceMapping.length > 0) {
      referencia = "";

      for (var r in this.referenceMapping) {
        var rm = this.referenceMapping[r];

        let element = this.fieldMapping.find((e) => e.fKey == rm);

        if (element) {
          referencia += this.records[0][element.fName];
        } else {
          referencia += rm;
        }
      }
    }

    return referencia;
  }

  goToEnvios() {
    
    this.$router.push({ name: "envioslist" });
  }

  mapToEnvios() {
    debugger;
    let envios: Envio[] = [];

    this.records.forEach((r) => {
      var envio = new Envio();
      let envioDatos: EnvioDato[] = [];
      for (var gf in this.groupedFields) {
        let arr = this.groupedFields[gf];
        let value = "";

        let campo = this.camposSelect.filter(
          (e) => e.value == arr[0].mName.key
        )[0];

        if (campo.value !== "nomap") {
          if (campo.custom == true) {
            let dato = new EnvioDato();
            dato.campoId = campo.id;
            arr.forEach((element) => {
              value += r[element.fName];
              value += " ";
            });
            dato.texto = value.trim();
            envioDatos.push(dato);
          } else {
            arr.forEach((element) => {
              value += r[element.fName];
              value += " ";
            });

            envio[arr[0].mName.key] = value.trim();
          }
        }
        debugger;
      }
      debugger;
      if (this.referenceMapping && this.referenceMapping.length > 0) {
        envio.referencia = "";

        for (var ix in this.referenceMapping) {
          var rm = this.referenceMapping[ix];

          let element = this.fieldMapping.find((e) => e.fKey == rm);

          if (element) {
            envio.referencia += r[element.fName];
          } else {
            envio.referencia += rm;
          }
        }
        debugger;
      }

      envio.envioDato = envioDatos;
      envio.campanaId = this.campana.id;
      envios.push(envio);
    });

    this.envios = envios;
    this.current = this.current + 1;
  }

  loadEnvios() {
    (
      this.$refs.ruleForm as Vue & { validate: (valid) => Promise<boolean> }
    ).validate(async (valid) => {
      if (valid) {
        this.actions.push("Inicio importación");
        var importacion = new Importacion();
        importacion.fichero = this.fileList[0].name;
        importacion.origen = this.form.name;
        importacion.observaciones = this.form.desc;
        importacion.mailing = this.form.mailing;

        importacion.estadoInicialEnvioId = this.form.envtype;
        importacion.fechaEnviado = this.form.fecenv;

        importacion.envios = this.envios;

        await this.$store
          .dispatch({
            type: "envio/importar",
            data: importacion,
          })
          .then(() => {
            this.actions.push("Transferncias cargadas correctamente");
            this.next();
          })
          .catch(() => {
            this.actions.push("No se han podido cargar las transferencias");
          });
      } else {
        return false;
      }
    });
  }
}
