import Entity from '../entity'
import { EstadoParticipacion } from '@/store/entities/OZONE/estadoParticipacion';
import SubEstadoParticipacion  from '@/store/entities/OZONE/subEstadoParticipacion';
import Ticket from './ticket';
import Transferencia from './transferencia';
import EnvioDato from './envioDato';
import ParticipacionComentario from './participacionComentario';

export default class Envio extends Entity<number>{
    nombre: string;
    apellido1: string;
    apellido2: string;
    email: string;
    telefono: string;
    sexo: string;
    dNI: string;
    campanaId: number;
    campanaNombre: string;
    campanaAxapta: string;
    envioDatoId: Array<number>;
    envioDato: Array<EnvioDato>;
    //estadoEnvio: EstadoParticipacion;
    estadoEnvioId: number;
    articulo: string;
    articuloUnidades: number;
    descripcionArticulo: string;
    direccion: string;
    cP: string;
    localidad: string;
    provincia: string;
    pais: string;
    metodoEnvioId: number;
    motivoDevolucionId: number;
    motivoDevolucion: string;
    participacionId: number;
    importacionId: number;
    servicioMensajeriaId: number;
    referencia: string;
    ekon: string;
    incentivo: string;
    creationTime: string
    fechaSalida: string
    fechaEnTramite: string
    fechaEntrega: string
    fechaDevolucion: string
    fechaEntregaEstimada: string
    urgente: boolean
    
}