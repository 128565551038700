import Entity from '../entity'
export default class EnvioDato extends Entity<number>{
    campoId: number;
    envioId: number;
    numerico: number;
    texto: string;
    decimal: number;
    siNo: boolean;
    fecha: string;
    url: string;
    
}